import axios from 'axios'
import store from '@/store'

const service = axios.create({
    // baseURL: 'https://api.scszcb.com/', // url = base url + request url
    baseURL: 'https://api.scszcb.com/szow', // url = base url + request url
    timeout: 5000, // request timeout
})

service.interceptors.response.use(
    response => {
        return response.data
    },
    error => {
        return Promise.reject(error)
    }
)
export default service
