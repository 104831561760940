 <template>
	<div class="index_box"  id="app">
		<div class="index_carousel">
			<el-carousel indicator-position="outside">
			  <el-carousel-item v-for="item in carouseData" :key="item">
				<div class="copywriting"  >
          <h1 text="2xl" justify="center">{{ item.content }}</h1>
          <div class="text_bg"> <img src="../assets/img/text_bg.png" alt=""></div>
		  <h3 text="2xl" justify="center">{{ item.title }}</h3>

				</div>
				<img :src="item.img" alt=""/>
			  </el-carousel-item>
			</el-carousel>
		</div>
		<div class="">
			<div class="index_title">
				<h2>业务范围</h2>
				<p>-BUSINESS-</p>
			</div>
			 <div class="business_scope">
				  <el-tabs type="border-card" class="demo-tabs">

				    <el-tab-pane >
				       <template #label>
						<div class="business_icon">
							<img src="../assets/img/business1.png" alt="">
						</div>
						<div class="business_icon_not" >
							<img src="../assets/img/business1.png" alt="">
						</div>
				         <span class="custom-tabs-label">
				           <span>网络游戏</span>
				         </span>
				       </template>
				     <div class="network">
						 <div class="introduce">
							 <div class="introduce_left">
								 <h2>网络游戏</h2>
								 <p>四川数字出版传媒有限公司是四川省网络出版重要单位，已有网络出版物类目包括已出版图书、报纸、杂志、学术、教育出版物等内容以及经过编辑加工的原创文学或者在其他媒体上公开发表的作品。自主搭建运营的原创网络文学出版平台苍穹悦读，提供一站式全包揽，写作指导、电子书排版、封面设计、渠道分发等有专业团队负责，实现平台直签+全渠道分发，顶级流量曝光</p>
							 </div>
							 <div class="introduce_right">
								 <h3>主营业务推荐</h3>
                 <ul class="business">
                   <li v-for="item in publish" key="item.id"  >
                     <router-link  :to="{path:'NetworkPublishing',query:{name:item.id}}">
                       <div class="business_text">
                         <h6>网络游戏</h6>
                         <p>{{ item.label }}</p>
                       </div>
                       <div class="next_bg"><img src="../assets/img/next.png" alt=""></div>
                     </router-link>
                   </li>

                 </ul>

							 </div>
						 </div>
					 </div>

					 </el-tab-pane>

					 <el-tab-pane>
					   <template #label>
					 					<div class="business_icon">
					 						<img src="../assets/img/business2.png" alt="">
					 					</div>
					 					<div class="business_icon_not" >
					 						<img src="../assets/img/business2.png" alt="">
					 					</div>
					     <span class="custom-tabs-label">

					       <span>网络动漫</span>
					     </span>
					   </template>
					  <div class="network">
					  						 <div class="introduce">
					  							 <div class="introduce_left">
					  								 <h2>网络动漫</h2>
					  								 <p>新华文轩四川数字出版传媒有限公司下设音乐艺术部门，已出版《巴蜀儿女心向党——四川省庆祝中国共产党成立100周年文艺演出台前幕后》《首届“四川省十大歌曲”作品集》《蜀韵飞歌唱中国——“中国梦”主题新创作歌曲集》等；在民族民间音乐收集整理和出版方面，出版了《传统二十四村千户锅庄集》《阿哈巴拉调——摩梭民间歌曲集成》《高原仰遗声——川西藏族山歌歌曲集》等，并获得国家、省市多项荣誉；与四川省音乐家协会、四川省卫健委合作，出版了《健康四川 幸福你我》《四川十大癌症预防与筛查建议》等电子出版物。</p>
					  							 </div>
					  							 <div class="introduce_right">
					  								 <h3>主营业务推荐</h3>
                             <ul class="business">
                               <li v-for="item in music" key="item.id"  >
                                 <router-link :to="{path:'Music_art',query:{name:item.id}}">
                                 <div class="business_text">
                                   <h6>网络动漫</h6>
                                   <p>{{ item.label }}</p>
                                 </div>
                                 <div class="next_bg"><img src="../assets/img/next.png" alt=""></div>
                                 </router-link>
                               </li>

                             </ul>

					  							 </div>
					  						 </div>
					  </div>

					 </el-tab-pane>

					  <el-tab-pane>
					    <template #label>
					  		<div class="business_icon">
					  			<img src="../assets/img/business3.png" alt="">
					  		</div>
					  		<div class="business_icon_not" >
					  			<img src="../assets/img/business3.png" alt="">
					  		</div>
					      <span class="custom-tabs-label">

					        <span>展会活动</span>
					      </span>
					    </template>
              <div class="network">
                <div class="introduce">
                  <div class="introduce_left">
                    <h2>展会活动</h2>
                    <p>作为国家新闻出版署批准设立的出版业科技与标准综合重点实验室区块链版权应用研究中心，打通建成可信区块链服务基础设施、版权公共服务平台知信链、DPIP数字出版发行平台、区块链数字资产管理器、文化数字资产发行平台、产权（数字）交易所、苍穹元宇宙、文化数字资产出海平台，提供从版权登记到数字资产交易、应用的全链路解决方案，推进文化产业跨足区块链、元宇宙的数字版权、数字资产创新领域，促进文化产业在价值互联网创新发展新技术、新模式、新业态、新场景，助力数字中国建设。</p>
                  </div>
                  <div class="introduce_right">
                    <h3>主营业务推荐</h3>
                    <ul class="business">
                      <li v-for="item in blockchain" key="item.id"  >
                        <router-link :to="{path:'BlockChain',query:{name:item.id}}">
                          <div class="business_text">
                            <h6>展会活动</h6>
                            <p>{{ item.label }}</p>
                          </div>
                          <div class="next_bg"><img src="../assets/img/next.png" alt=""></div>
                          </router-link>
                      </li>

                    </ul>

                  </div>
                </div>
              </div>
					  </el-tab-pane>
				 <!--  <el-tab-pane>
				   					    <template #label>
				   				<div class="business_icon">
				   					<img src="../assets/img/business4.png" alt="">
				   				</div>
				   				<div class="business_icon_not" >
				   					<img src="../assets/img/business4.png" alt="">
				   				</div>
				   					      <span class="custom-tabs-label">
				   					        <span>游戏+</span>
				   					      </span>
				   					    </template>
            <div class="network">
              <div class="introduce">
                <div class="introduce_left">
                  <h2>游戏+</h2>
                  <p>作为新华⽂轩出版传媒股份有限公司旗下唯⼀拥有互联⽹游戏出版资质的单位，公司游戏业务已进入“游戏+”阶段，提供包括前置审核、游戏出版、游戏展会、游戏周边等多种形式的服务。
                    公司拥有⼀批专业的游戏编辑团队，熟悉游戏出版流程，修改意见专业，游戏申报对接迅速，提供全程托管式服务，省心省力。游戏出版经历了“单机版游戏——网络游戏——⼿机游戏”的发展历程，已出版的游戏获国家、省部级奖项，在业内享有声誉，目前已获批游戏近两百款。公司作为四川版协游戏出版工委主任单位，全力助推游戏行业健康发展。</p>
                </div>
                <div class="introduce_right">
                  <h3>主营业务推荐</h3>
                  <ul class="business">
                    <li v-for="item in game" key="item.id" >
                      <router-link :to="{path:'Game',query:{name:item.id}}">
                      <div class="business_text">
                        <h6>游戏+</h6>
                        <p>{{ item.label }}</p>
                      </div>
                      <div class="next_bg"><img src="../assets/img/next.png" alt=""></div>
                      </router-link>
                    </li>

                  </ul>


                </div>
              </div>
            </div>
				   					  </el-tab-pane> -->
									  
				   </el-tabs>
			 </div>

		</div>
	<div style="display: none" class="aptitude">
		<div class="index_title">
			<h2>资质能力</h2>
			<p>-HONOR-</p>
		</div>
		 <div class="qualification_box">
       <swiper class="swiperWrap"
           :slides-per-view="4"
           :space-between="20"
           :loop="true"
           :autoplay="{autoplay:true,delay:3000}"
           @swiper="onSwiper"
           @slideChange="onSlideChange"
           @slidePrevTransitionEnd = "onSlidePrevTransitionEnd"
           @slideNextTransitionEnd = "onSlideNextTransitionEnd"
       >
         <swiper-slide    v-for="item in honor" :key="item"><img :src="item.img" alt=""></swiper-slide>
       </swiper>
       <div class="awards">
          <p>资质齐备 能力出众 </p>
         <ul>
           <li>
             <p>20+</p>
             <span>企业资质</span>
           </li>
           <li>
             <p>10项</p>
             <span>出版范围</span>
           </li>
           <li>
             <p>100+</p>
             <span>企业荣誉</span>
           </li>
         </ul>
       </div>
     </div>

	</div>

	<div class="Company_news">
		<div class="news_bg">
			<div class="index_title">
				<h2>公司新闻</h2>
				<p>-NEWS-</p>
			</div>
		</div>
		<div class="new_box">
			<ul class ="news_list"  >
				<div class="news_top">
					<div class="news_t">
						新闻动态
					</div>
					<div class="new_more"><router-link to="news_more">加载更多>></router-link></div>
				</div>
				<li  v-for="item in message.slice(0, 5)" :key="item"   >
					<router-link :to="{path:'NewsDetails',query:{id:item.id}}"   >
						<!-- <div class="cover_img">
							<img :src="item.img" alt="">
						</div> -->
						<h3>{{item.title}}</h3>
	<!--					<p>{{item.content}}</p>-->
					</router-link>
				</li>

			</ul>
			<ul class ="news_list"  >
				<div class="news_top">
					<div class="news_t">
						信息公开
					</div>
					<div class="new_more"><router-link to="information">加载更多>></router-link></div>
				</div>
						<li  v-for="item in messages.slice(0, 5)" :key="item"   >
							<router-link :to="{path:'NewsDetails',query:{id:item.id}}"   >
								<!-- <div class="cover_img">
									<img :src="item.img" alt="">
								</div> -->
								<h3>{{item.title}}</h3>
			<!--					<p>{{item.content}}</p>-->
							</router-link>
						</li>
			
					</ul>
		</div>
         
	</div>
	<div class="cooperation">
		<div class="index_title">
			<h2>合作机构</h2>
			<p>-PARTNERS-</p>
		</div>
		<ul class="cooperation_list">
			<li v-for="item in institution" :key="item"><router-link to=""><img :src="item.img" alt=""></router-link></li>
		</ul>
	</div>
	</div>

	<Footer></Footer>
 </template>
 <script  >
	 import { Calendar } from '@element-plus/icons-vue'
	 import Footer from '@/components/Footer'
   // Import Swiper Vue.js components
   import SwiperCore, { Navigation,Virtual,Autoplay, Pagination, Scrollbar, A11y } from 'swiper';
   import { Swiper, SwiperSlide } from 'swiper/vue';
   import 'swiper/swiper-bundle.css';
   import {banner, honor, message,institution,primary} from '@/api/home.js'

   SwiperCore.use([Navigation, Virtual,Autoplay,Pagination, Scrollbar, A11y]);
 export default{
	   components: {
	     Footer: Footer,
       Swiper,
       SwiperSlide,
	   },
	 data() {
		 return {
			carouseData:[],
       honor:[],
       message:[],
	   messages:[],
       institution:[],
       publish:[],//网络出版
       music:[],//音乐艺术
       blockchain:[],//区块链
       game:[],//游戏
		 }
	 },
	 methods:{
     publish_to( ) {
       //这样才能获取每个el-tab-pane的name属性
       // router.push({path:'Music_art',query:{name:tab.props.name}});
     },
	 },
   created() {
     banner().then(res => {
       this.carouseData=res.data
     })
     honor().then(res => {
       this.honor=res.data
       console.log( this.honor)
     })
	 const newsparams = {
	   type:5,
	 }
	 message(newsparams).then(res => {
	   this.messages=res.data
	 })
     message().then(res => {
       this.message=res.data
     })
     institution().then(res => {
       this.institution=res.data
     })
     const params = {
       service: 'publish'
     }
     const param_music = {
       service: 'music'
     }
     const param_blockchain = {
       service: 'blockchain'
     }
     const param_game = {
       service: 'game'
     }
     primary(params).then(res => {
       this.publish=res.data
       console.log(res.data,11)
     })
     primary(param_music).then(res => {
       this.music=res.data
	    console.log(res.data,22)
     })
     primary(param_blockchain).then(res => {
       this.blockchain=res.data
       console.log(res.data)
	    console.log(res.data,33)
     })
     primary(param_game).then(res => {
       this.game=res.data
       console.log(res.data,44)
     })
   }
 }
 </script>


<style scoped>
.demonstration {
  color: var(--el-text-color-secondary);
}

/*@font-face {*/
/*  font-family: 'aiqianjin';*/
/*  src: url("../assets/img/aiqianjin.ttf");*/
/*}*/

.index_box{
	width: 100%;
  margin-top: -100px;
	overflow: hidden;
}
.el-carousel__item .copywriting{
	width: 100%;
	position: absolute;
	top:36%;
	left: 0;
}
.el-carousel__item .text_bg{
  width: 1200px;
  margin: 0 auto;
}
.el-carousel__item .text_bg img{
  width: 300px!important;
  height: auto!important;
  margin:20px auto 0;
}
>>>.index_carousel .el-carousel__item img{
	width: 100%;
	height: 600px;
}
.index_carousel .el-carousel__item .copywriting h3{
	width: 1200px;
    margin: 20px auto 0;
	font-weight: bold;
	font-size: 27px;
	color: #CA5608;
}
.index_carousel .el-carousel__item .copywriting h1{
	width: 1200px;
  margin: 0 auto;
	font-size: 74px;
  font-weight: 400;
	/*color: transparent;*/
  background-image:-webkit-linear-gradient(top, #31221B 9%,#A05426 91%);
  -webkit-background-clip:text;
  -webkit-text-fill-color:transparent;
  letter-spacing: 0px;
  font-family: 'Art'!important;
}


 >>>.index_carousel .el-carousel__container{
	 width: 100%;
	height: 600px!important;
 }
 >>>.index_carousel .el-carousel__arrow{
	 background-color: #fff;
	 color: #009ad3;
	 font-size: 18px;
 }
  >>>.index_carousel .el-carousel__arrow:hover{
	  background-color: #fff;
	  color: #009ad3;
	  font-size: 18px;
  }
  >>>.index_carousel .el-carousel__indicators{
	  display: none;
  }
  .index_title{
	  margin-top: 60px;
	  text-align: center;
  }
  .index_title h2{
	  font-size: 36px;
	  	font-weight: inherit;
	  	color: #333333;
  }
  .index_title p{
	  	font-size: 20px;
	  	color: #999999;
		margin-top: 8px;
  }
  .business_scope{
	  width: 100%;
  }
  >>>.el-tabs--border-card{
	   border: none;
  }
>>>.el-tabs--border-card>.el-tabs__header .el-tabs__item:not(.is-disabled):hover {
  color:  #e76b23!important;
}
  >>>.el-tabs--border-card>.el-tabs__header{
	  width: 1200px;
	  margin: 40px auto 0;
	  background-color: #fff;
	  border: none;
  }
  >>>.el-tabs__item{
	  width: 120px;
	      padding: 0;
	      height:120px;
		  /* line-height: 60px; */
	      box-sizing: border-box;
	      display: block;
	      list-style: none;
	      font-size: var(--el-font-size-base);
	      font-weight: 500;
	      color: var(--el-text-color-primary);
	      position: relative;
		  text-align: center;
  }
  >>>.el-tabs__item:nth-child(2){
	  padding-left: 0;
  }
  >>>.el-tabs--top.el-tabs--border-card>.el-tabs__header .el-tabs__item:last-child{
	  padding-right: 0;
  }
  .business_icon{
	  display: none;
  }
  .is-active .business_icon{
	  height: 90px;
	  line-height: 90px;
	  display: block;
  }
   .is-active  .business_icon img{
		vertical-align: middle;
	}
.business_icon_not{
	  height: 90px;
	  line-height: 90px;
	  display: block;
  }
     .is-active .business_icon_not{
		  display: none;
	 }
   .business_icon_not img{
		vertical-align: middle;
	}
 >>>.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
	background-color: #f4f5f7;
	color: #1b1b1b;
 }
 >>>.el-tabs--border-card>.el-tabs__content{
	 padding: 0;
 }
 .network{
	 width: 100%;
	 height: 433px;
	 background:url(../assets/img/network_bg.png);
	 background-size: 100% 100%;
 }


 /* 资质 */
  .carousel-item {
    color: #475669;
    opacity: 0.75;
    margin: 0;
    text-align: center;
  }

  .el-carousel__item h3 {
    color: #475669;
    opacity: 0.75;
    display: flex;
    align-items: center;
    margin: 0;
    text-align: center;
    height: 100%;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
  >>>.el-tabs__header{
    box-shadow: none!important;
  }





  @media screen and (max-width: 768px) {
  html,body{
    width: 100%;
    overflow-x:hidden ;
  }
    .header{
      width: 100%;
      height:100px;
      position: absolute;
      top: 0;
      line-height: 40px;
      left: 0;
      z-index: 333;
    }
    .head{
      width:96%;
      height: auto;
      margin: 0 auto;
      display: block;
    }
    .logo{
      width: 40%;
      height: 60px;
      line-height: 60px;
    }
    .logo img{
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
    .header_nav{
      width: 100%;
      display: flex;
      font-size: 12px;
      color: #f9fafa;
    }
    .header_nav>li{
      width: 20%;
      height: 40px;
      line-height: 40px;
      margin-left:0;
      text-align: center;
    }
    nav a.router-link-exact-active {
      /*color: #42b983;*/
      display: block;
      height: 40px;
      border-bottom: 2px solid #fff;
    }
    .pop-up{
      min-width:100%;
      height: 292px;
      background:rgba(0,0,0,.8);
      position: absolute;
      top: -292px;
      left: 0;
      z-index: 222;
      display: none;
      /*transition: top 0.5s, transform 0.5s;*/
    }
    .header_nav li:hover .pop-up{
      top: 100px;
    }
    .pop-up-content{
      width: 1200px;
      margin: 0 auto;
      display: flex;
    }
    .recommended{
      width: 205px;
      height: 205px;
      margin-top: 40px;
    }
    .recommended img{
      width: 205px;
      height: 205px;
    }
    .second_level{
      width: 935px;
      margin :25px 0 0 62px;
    }
    .second_level h3{
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #fff;
    }
    .second_level_list {
      width: 510px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .second_level_list li{
      width: 173px;
      height: 50px;
      display: flex;
      line-height: 20px;
      margin-top: 34px;
    }
    .second_level_icon{
      width: 50px;
      height: 50px;
      line-height: 50px;
    }
    .second_level_icon img{
      height: 34px;
      vertical-align: middle;
    }
    .second_level_list li h6{
      font-size: 16px;
      color: #fffefe;
      width:132px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 3px;
    }
    .second_level_list li p{
      font-size: 14px;
      color: #e5e5e5;
      width:132px;
      white-space: nowrap;
      overflow: hidden;
      margin-top: 8px;
      text-overflow: ellipsis;
    }
  }


  .introduce{
    width: 1200px;
    margin: 0 auto;
    display: flex;
  }
  .introduce_left{
    width: 180px;
    color: #1b1b1b;
  }
  .introduce_left h2{
    font-size: 24px;
    font-weight: inherit;
    color: #1b1b1b;
    margin: 36px 0 27px;
  }
  .introduce_left p{
    width: 180px;
    height: 180px;
    font-size: 14px;
    line-height: 30px;
    color: #1b1b1b;
    display: none!important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
  }
  .introduce_right{
    width: 900px;  height: 370px;
    margin :36px 0 0 40px;
  }
  .introduce_right h3{
    font-size: 18px;
    font-weight: bold;
    color: #1b1b1b;
    margin-bottom: 22px;
  }
  .business{
    width: 60%;
    display: flex;
    flex-wrap: wrap;
  }
  .business li{
    width:30%;
    height: 66px;
    font-size: 18px;
    color: #1b1b1b;
    margin: 0 0 6% 3.1% ;
    display: flex;
     background: url("../assets/img/business_bg.png")no-repeat;
    background-size: 100% 100%;
  }

.business li a {
  width: 100%;
  height: 66px;
  display: flex;
}
.business li:nth-child(3n+1){
  margin-left: 0;
}
.business   li:hover {
  background: url("../assets/img/active_bg.png")no-repeat;
  color: #fff;
}

  .business_text{
    width: 80%;
    height: 100%;
    margin-left: 15px;
  }
.business_text h6{
    width: 100%;
    font-size: 12px;
    margin: 12px 0 5px ;
  }
.business_text p{
    font-size: 16px;
  }
.next_bg{
  width: 47px!important;
  height: 36px;
  display: block;
  line-height: 33px;
  background-color: #ffffff;
  border-radius: 100%;
  text-align: center;
  margin : 15px 8px 0 0;
}
.next_bg img{
  vertical-align: middle;
}
  /* .scrollbar-demo-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin: 10px;
    text-align: center;
    border-radius: 4px;
  } */
  .sort{
    width: 900px;
    margin-top: 26px;
  }
  .sort h6{
    font-size: 16px;
    color: #ffffff;
  }
  .sort_list {
    display: flex;
    margin-top: 12px;
  }
  .sort_list li{
    width: 116px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(0deg,
    #135ed9 0%,
    #00b7ee 46%,
    #2cc1ff 100%);
    border-image-slice: 1;
    font-size: 16px;
    color: #ffffff;
    margin-right: 24px;
  }

  /*公司资质*/
  .qualification_box{
    width: 100%;
    height: 340px;
    position: relative;
    margin : 40px 0 60px;
  }
  .qualification_box .qualification_list{
    display: flex;
  }
  .qualification_box .swiperWrap{
    width: 817px;
    height: 340px;
    margin-left: 570px;
    line-height: 340px;
  }
  .swiper-slide{
    width: 210px;
    height: 340px;
    line-height: 340px;
  }
  .swiper-slide img{
    width: 185px;
    vertical-align: middle;
  }
  .qualification_box .qualification_list li{
    margin-left: 27px;
  }
  .qualification_box .qualification_list li:first-child{
    margin-left: 0;
  }
  .qualification_box .qualification_list .qualification_cross{
    margin-top: 201px;
  }
  .qualification_box .qualification_list .qualification_cross,.qualification_box .qualification_list .qualification_cross img{
    width: 185px;
    height: 139px;
  }
  .qualification_cross img{
    margin-top: 200px!important;
  }
  .qualification_box .qualification_list .qualification_vertical,.qualification_box .qualification_list .qualification_vertical img{
    width: 191px;
    height: 340px;
  }
  .awards{
    width: 320px;
    height: 125px;
    position: absolute;
    top: 55px;
    left: 190px;
    z-index: 8;
  }
  .awards>p{
    font-size: 18px;
    color: #333333;
  }
  .awards ul{
    margin-top: 26px;
    display: flex;
  }
  .awards ul li{
    padding:0 10px;
    border-right: solid 1px #dbdbdb;;
  }
  .awards ul li p{
    font-size: 42px;
    height: 50px;
    overflow: hidden;
    color: #333333;
  }
  .awards ul li span{
    display: block;
    margin-top: 8px;
    text-align: center;
  }
  .awards ul li:first-child{
    padding-left: 0;
  }
  .awards ul li:last-child{
    border-right: none;
  }
  /* 公司新闻 */

  .Company_news{
    width: 100%;
    height: 630px;

  }

  .news_bg  {
    width: 100%;
    overflow: hidden;
    /* background: url(../assets/img/news_bg.png)no-repeat; */
    /* background-size: 100% 100%; */
    z-index: 2;
  }
  .new_box{
    width: 1200px;
    height: 413px;
    padding: 30px 30px 40px ;
    z-index: 3;
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
  }
  .news_list{
    width: 570px;
    height: 410px;
    display: flex;
	flex-direction: column;
	box-shadow: 0px 0px 27px 0px
	rgba(90, 90, 90, 0.07);
	 border-top-left-radius: 37px; 
  }
  .news_top{
  width: 100%;
  height: 66px;
	background: url(../assets/img/top_bg.png)no-repeat;
	background-size: 100% 100%; 
	 display: flex;
	 justify-content: space-between;
	 border-top-left-radius: 20px;
	 align-items: center;
	 margin-bottom: 18px;
  }
  .news_t{
	  height: 66px;
	  line-height: 66px;
	  margin-left: 34px;
	  font-weight: bold;
	  font-size: 22px;
	  color: #1B1B1B;
  }
  .news_list li{
    width: 100%;
    height: 42px;
    margin-left: 0px;
	line-height: 42px;
    /* box-shadow: 0px 0px 27px 0px rgba(90, 90, 90, 0.07); */
	margin-top: 12px;
  }
  .news_list li:first-child{
    margin-top: 0px;
  }
  .cover_img {
    width: 272px;
    height: 245px;
    text-align: center;
    line-height: 245px;
    overflow: hidden;
  }
  .cover_img img{
    /* height: 245px; */
	width: 100%;
    vertical-align: middle;
  }
  .news_list li h3{
    width: 100%;
    margin: 0 34px;
    font-size: 16px;
    color: #333333;
    max-width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	border-bottom: 1px solid #F1D4C0;
  }
  .news_list li:hover h3{
    color: #E56012;
  }
  .news_list li p{
    width: 250px;
    margin:10px auto 0;
    font-size: 14px;
    color: #999999;
    line-height:20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp:3;
  }
  .new_more{
    font-size: 14px;
    color: #3A3A3A;
	margin-right: 34px;
  }
  /* 合作机构 */
  .cooperation{
    width: 1200px;
    margin: 0 auto;
  }
  .cooperation_list{
    width: 1200px;
    margin : 20px 0;
    display: flex;
    flex-wrap: wrap;
  }
  .cooperation_list li{
    width: 262px;
    height: 96px;
    margin:22px 0 0 50px;
    background-color: #fff;
    box-shadow: 0px 0px 27px 0px rgba(90, 90, 90, 0.07);
  }
  .cooperation_list li:nth-child(4n+1){
    margin-left: 0;
  }
  .cooperation_list li a{
    display: block;
    width: 262px;
    height: 96px;
    text-align: center;
    line-height: 96px;
    overflow: hidden;
  }
  .cooperation_list li a img{
    height: 90px;
    vertical-align: middle;
  }






  @media screen and (max-width: 768px)  {
    .index_carousel{
      width: 100%;
    }
    >>>.index_carousel .el-carousel__container{
      width: 100%;
      height: 350px!important;
    }
    .el-carousel__item .copywriting {
      width: 100%!important;
      position: absolute!important;;
      top:40%!important;
      left: 0px!important;
    }
    .index_carousel .el-carousel__item img {
      width:auto!important;
      height: 350px;
    }
    .index_carousel .el-carousel__item .copywriting h3 {
      width: 80%;
      margin: 0 auto;
      font-size: 20px;
      color: #ffffff;
    }
    .introduce{
      width: 90%;
    }
    .index_title h2 {
      font-size: 30px;
    }
    .awards{
      display: none;
    }
    .qualification_box .swiperWrap{
      width:96%;
      background-color: #fff;
      margin: 0 auto;
      height: 160px;
      line-height: 160px;
    }

    .swiper-slide{
      width: 25%;
      height: 160px;
      line-height: 160px;
    }
    .swiper-slide img {
      width: 100%;
      vertical-align: middle;
    }
    .qualification_box{
      height: 160px;
      line-height: 160px;
      margin: 20px 0 10px;
}
.new_box{
  width: 96%!important;
  padding:  0;
  height: auto;
  margin:0 auto;
  display: flex;
  flex-direction: column;
}
.Company_news{
	height: auto;
}
.news_list {
  display: flex;
  width: 100%;
  margin-top: 20px;
      height: 350px;

}
.news_top{
	height: 50px;
}
.news_t{
    height: 50px;
    line-height: 50px;
    margin-left: 34px;
    font-weight: bold;
    font-size: 18px;
    color: #1B1B1B;
	}
.news_list li{
    width: 100%;
  height: 46px;
  margin :2% 0 0 2%;
}
    .news_list li:nth-child(2n+1){
      margin-left: 0;
    }
    .cover_img{
      width: 100%;
      height: 200px;
    }
    .cover_img img{
      width: 100%;
      height: auto;
  }
    .news_list li h3{
      width: 84%;
      height: 30px;
      line-height: 30px;
      margin:0 auto;
      font-size: 14px;
    }
.index_carousel .el-carousel__item .copywriting h1{
    width: 80%;
    margin: 20px auto 0;
    font-size: 30px;
    line-height: 40px;
    color: #ffffff;
}

.index_title {
margin-top: 30px;
text-align: center;
}
>>>.el-tabs--border-card>.el-tabs__header{
width: 100%;
margin: 40px auto 0;
background-color: #fff;
border: none;
}
>>>.el-tabs__nav{
width: 100%;
height: 90px;
}
>>>.el-tabs__item{
width: 100%;
}
.is-active .business_icon,.business_icon_not{
height: 60px;
line-height: 60px;
display: block;
}

.business_icon img  ,.business_icon_not img{
width: 30px;
}
.introduce_left{
display: none;
}
.introduce_right h3{
margin-bottom: 0;
}
.sort {
width: 100%;
margin-top: 10px;
}
.introduce_right{
width: 100%;
height: 370px;
margin: 36px 0 0 0px;
}
    .cooperation {
      width: 96%;
      margin: 0 auto;
    }
    .cooperation_list {
      width: 100%;
      margin-top: 0px;
      display: flex;
      flex-wrap: wrap;
    }
    .cooperation_list li{
      width: 49%;
      height: 96px;
      margin: 22px 0 0 2%;
      background-color: #fff;
      box-shadow: 0px 0px 27px 0px rgba(90, 90, 90, 0.07);
    }
    .cooperation_list li:nth-child(2n+1){
      margin-left: 0;
    }
    .cooperation_list li a {
      display: block;
      width: 100%;
      height: 96px;
      text-align: center;
      line-height: 96px;
      overflow: hidden;
    }
    .cooperation_list li a img  {
      height: 50%;
      vertical-align: middle;
    }
    .business {
      width: 98%;
      display: flex;
      margin: 8% auto 0;
      flex-wrap: wrap;
    }
    .business li{
      width: 47.5%;
      padding: 0;
      margin-left: 0%;
    }
    .business li:nth-child(even){
      margin-left: 5%;
    }
  }
</style>
