import request from '@/utils/request'

export function banner(data) {
    return request({
        url: 'api/banner/list',
        method: 'get',
        data
    })
}

export function honor(data) {
    return request({
        url: '/api/honor/list',
        method: 'get',
        params: data
    })
}

export function message(data) {
    return request({
        url: '/api/new/list',
        method: 'get',
        params: data
    })
}
export function institution(data) {
    return request({
        url: '/api/org/list',
        method: 'get',
        params: data
    })
}
export function newId(data) {

    return request({
        url: '/api/new',
        method: 'get',
        params: data
    })
}
// 网络出版
export function NetworkPublishing(data) {

    return request({
        url: '/api/biz/list',
        method: 'get',
        params: data
    })
}
// 网络出版详情
export function publishdetail(data) {

    return request({
        url: '/api/publish/detail',
        method: 'get',
        params: data
    })
}
// 上一篇
export function previous(data) {

    return request({
        url: '/api/new/previous',
        method: 'get',
        params: data
    })
}
// 下一篇
export function next(data) {
    return request({
        url: '/api/new/next',
        method: 'get',
        params: data
    })
}
// 询价购买
export function enquiry(data) {
    return request({
        url: '/api/biz/enquiry',
        method: 'post',
        data: data
    })
}
// 音乐出版详情
export function musicdetail(data) {

    return request({
        url: '/api/music/detail',
        method: 'get',
        params: data
    })
}
// 关于我们
export function introductiona(data) {

    return request({
        url: '/api/introduction/list',
        method: 'get',
        params: data
    })
}
//  荣誉展示
export function certificate(data) {

    return request({
        url: '/api/certificate/list',
        method: 'get',
        params: data
    })
}
//  一级分类
export function primary(data) {

    return request({
        url: 'api/biz/primary',
        method: 'get',
        params: data
    })
}
export default { banner, honor ,message ,institution,publishdetail,previous,next,musicdetail,introductiona,certificate,primary}
